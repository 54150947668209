.app {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.navigation {
  background-color: #f8f9fa;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.nav-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  padding: 0.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
}

.nav-button img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

.nav-button span {
  font-size: 1rem;
  font-weight: 500;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

.chat-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  background: #3498db;
  color: white;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  background: #2980b9;
}

.chat-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-container {
  flex: 1;
  position: relative;
  height: calc(100vh - 120px);
  overflow: hidden;
}

.chat-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

h1 {
  color: #2c3e50;
  margin-bottom: 40px;
  text-align: center;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .nav-buttons {
    gap: 1rem;
  }
  
  .nav-button img {
    width: 50px;
    height: 50px;
  }
  
  .nav-button span {
    font-size: 0.9rem;
  }
  
  .chat-container {
    height: calc(100vh - 100px);
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }
  
  .chat-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}
